import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Button } from '../../Component/Button/Button';
import classes from './AddContractAgreement.module.css';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MSG_REQUIRED_FIELD } from '../../utils/contants';
import {
  FormHelperText,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { apiHeader, BaseURL, imageUrl } from '../../config/apiUrl';
import { Get, Patch, Post } from '../../Axios/AxiosFunctions';
import { toast } from 'react-toastify';
import SideBarSkeleton from '../../Component/SideBarSkeleton';
import { useNavigate } from 'react-router-dom';
import ModalSkeleton from '../../modals/ModalSkeleton';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { FaRegUser } from 'react-icons/fa';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

import ContractInputFieldModal from '../../modals/ContractInputFieldModal';

const validationSchema = yup.object().shape({
  template: yup.string().required(MSG_REQUIRED_FIELD),
  content: yup.string().required(MSG_REQUIRED_FIELD),
});

const AddContractAgreement = ({ editData }) => {
  const navigate = useNavigate();

  const {
    access_token: accessToken,
    user,
    company_id: companyId,
  } = useSelector((state) => state?.authReducer);
  const logo = useSelector((state) => state?.companyReducer?.logo);

  const baseApiUrl = BaseURL(`contract-agreement`);

  const [isOpenContractModal, setIsOpenContractModal] = useState(false);
  const [listings, setListings] = useState([]);
  const [entities, setEntities] = useState([]);
  const [contractTemplates, setContractTemplates] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [cooperatingBroker, setCooperatingBroker] = useState([]);
  const [thirdPartyContacts, setThirdPartyContacts] = useState([]);

  const [isOpenContractInputFieldModal, setIsOpenContractInputFieldModal] =
    useState(false);

  const getAgreementContent = (content) => {
    if (content) {
      let replacedContent = content || '';

      const companyLogo = imageUrl(logo);

      if (companyLogo) {
        replacedContent = replacedContent?.replaceAll(
          '*|company_logo|*',
          `   
              <img style="
                width: 200px;
                height: 100%;
                object-fit: contain;
                object-position: center;
              " src="${companyLogo}" alt="logo" />`,
        );
      }

      if (values?.entity?.name) {
        replacedContent = replacedContent?.replaceAll(
          '*|entity_name|*',
          values?.entity?.name || '',
        );
      }

      if (values?.entity?.city) {
        replacedContent = replacedContent?.replaceAll(
          '*|entity_city|*',
          values?.entity?.city || '',
        );
      }

      if (values?.entity?.country) {
        replacedContent = replacedContent?.replaceAll(
          '*|entity_country|*',
          values?.entity?.country || '',
        );
      }

      if (values?.entity?.state) {
        replacedContent = replacedContent?.replaceAll(
          '*|entity_state|*',
          values?.entity?.state || '',
        );
      }

      if (values?.entity?.street) {
        replacedContent = replacedContent?.replaceAll(
          '*|entity_street|*',
          values?.entity?.street || '',
        );
      }

      if (values?.entity?.zipcode) {
        replacedContent = replacedContent?.replaceAll(
          '*|entity_zip|*',
          values?.entity?.zipcode || '',
        );
      }

      if (values?.entity?.phone) {
        replacedContent = replacedContent?.replaceAll(
          '*|entity_phone|*',
          values?.entity?.phone || '',
        );
      }

      if (values?.seller) {
        replacedContent = replacedContent
          ?.replaceAll('*|Seller_Name|*', values?.seller?.name || '')
          ?.replaceAll('*|seller_email|*', values?.seller?.email);
      }

      if (values?.buyer) {
        replacedContent = replacedContent
          ?.replaceAll('*|Buyer_Name|*', values?.buyer?.name || '')
          ?.replaceAll('*|buyer_email|*', values?.buyer?.email);
      }

      if (values?.broker?.name) {
        replacedContent = replacedContent?.replaceAll(
          '*|broker_name|*',
          values?.broker?.name || '',
        );
      }

      if (values?.broker_firm_name) {
        replacedContent = replacedContent?.replaceAll(
          '*|broker_firm_name|*',
          values?.broker_firm_name || '',
        );
      }

      if (values?.broker_provided_info) {
        replacedContent = replacedContent?.replaceAll(
          '*|broker_provided_info|*',
          values?.broker_provided_info || '',
        );
      }

      if (values?.business_name) {
        replacedContent = replacedContent?.replaceAll(
          '*|Business_Name|*',
          values?.business_name || '',
        );
      }

      if (values?.business_address) {
        replacedContent = replacedContent?.replaceAll(
          '*|Business_Address|*',
          values?.business_address || '',
        );
      }

      if (values?.cooperating_brokerage_name) {
        replacedContent = replacedContent?.replaceAll(
          '*|cooperating_brokerage_name|*',
          values?.cooperating_brokerage_name || '',
        );
      }

      if (values?.cooperating_brokerage_address) {
        replacedContent = replacedContent?.replaceAll(
          '*|cooperating_brokerage_address|*',
          values?.cooperating_brokerage_address || '',
        );
      }

      if (values?.commission_percentage) {
        replacedContent = replacedContent?.replaceAll(
          '*|commission_percentage|*',
          values?.commission_percentage || '',
        );
      }

      if (values?.commission_payment_terms) {
        replacedContent = replacedContent?.replaceAll(
          '*|commission_payment_terms|*',
          values?.commission_payment_terms || '',
        );
      }

      if (values?.cooperating_broker_name?.name) {
        replacedContent = replacedContent?.replaceAll(
          '*|cooperating_broker_name|*',
          values?.cooperating_broker_name?.name || '',
        );
      }

      if (values?.cooperating_broker_title) {
        replacedContent = replacedContent?.replaceAll(
          '*|cooperating_broker_title|*',
          values?.cooperating_broker_title || '',
        );
      }

      if (values?.cooperating_broker_phone) {
        replacedContent = replacedContent?.replaceAll(
          '*|cooperating_broker_phone|*',
          values?.cooperating_broker_phone || '',
        );
      }

      if (values?.cooperating_broker_email) {
        replacedContent = replacedContent?.replaceAll(
          '*|cooperating_broker_email|*',
          values?.cooperating_broker_email || '',
        );
      }

      if (values?.agreement_title) {
        replacedContent = replacedContent?.replaceAll(
          '*|Agreement_Title|*',
          values?.agreement_title || '',
        );
      }

      if (values?.agreement_date) {
        replacedContent = replacedContent?.replaceAll(
          '*|Agreement_Date|*',
          values?.agreement_date || '',
        );
      }

      if (values?.closing_date) {
        replacedContent = replacedContent?.replaceAll(
          '*|closing_date|*',
          values?.closing_date || '',
        );
      }

      if (values?.termination_reason) {
        replacedContent = replacedContent?.replaceAll(
          '*|Termination_Reason|*',
          values?.termination_reason || '',
        );
      }

      if (values?.clause_reference) {
        replacedContent = replacedContent?.replaceAll(
          '*|Clause_Reference|*',
          values?.clause_reference || '',
        );
      }

      if (values?.due_diligence_deadline) {
        replacedContent = replacedContent?.replaceAll(
          '*|Due_Diligence_Deadline|*',
          values?.due_diligence_deadline || '',
        );
      }

      if (values?.document_list) {
        replacedContent = replacedContent?.replaceAll(
          '*|Document_List|*',
          values?.document_list || '',
        );
      }

      if (values?.earnest_money_amount) {
        replacedContent = replacedContent?.replaceAll(
          '*|earnest_money_amount|*',
          values?.earnest_money_amount || '',
        );
      }

      if (values?.escrow_agent_name?.name) {
        replacedContent = replacedContent?.replaceAll(
          '*|escrow_agent_name|*',
          values?.escrow_agent_name?.name || '',
        );
      }

      if (values?.escrow_company_name) {
        replacedContent = replacedContent?.replaceAll(
          '*|escrow_company_name|*',
          values?.escrow_company_name || '',
        );
      }

      if (values?.buyer_execution_date_1) {
        replacedContent = replacedContent?.replaceAll(
          '*|buyer_execution_date_1|*',
          values?.buyer_execution_date_1 || '',
        );
      }

      if (values?.buyer_execution_date_2) {
        replacedContent = replacedContent?.replaceAll(
          '*|buyer_execution_date_2|*',
          values?.buyer_execution_date_2 || '',
        );
      }

      if (values?.seller_execution_date) {
        replacedContent = replacedContent?.replaceAll(
          '*|seller_execution_date|*',
          values?.seller_execution_date || '',
        );
      }

      if (values?.date_executed) {
        replacedContent = replacedContent?.replaceAll(
          '*|date_executed|*',
          values?.date_executed || '',
        );
      }

      return replacedContent;
    } else {
      return '';
    }
  };

  const convertToBase64 = (htmlContent) => {
    return new Promise((resolve, reject) => {
      const converted = htmlDocx.asBlob(htmlContent, {
        margins: { top: 520, bottom: 520, left: 520, right: 520 },
      });
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1]; // Extract the Base64 part
        resolve(base64String); // Resolve the Promise with the Base64 string
      };

      reader.onerror = (error) => reject(error); // Handle any errors that may occur

      reader.readAsDataURL(converted);
    });
  };

  const {
    errors,
    values,
    touched,
    handleSubmit,
    setFieldValue,
    setValues,
    isSubmitting,
    setSubmitting,
    resetForm,
    handleBlur,
  } = useFormik({
    initialValues: {
      status: editData?.status || 'draft',
      template: editData?.template,
      content: editData?.content || '',
      recipients: editData?.recipients || [],

      // Listing
      listing: editData?.listing,
      entity: editData?.entity,
      business_name: editData?.business_name || '',
      business_address: editData?.business_address || '',

      seller: editData?.seller,
      buyer: editData?.buyer,

      // broker: '',
      broker: editData?.broker || '',
      broker_firm_name: editData?.broker_firm_name || '',
      broker_provided_info: editData?.broker_provided_info || '',

      cooperating_brokerage_name: editData?.cooperating_brokerage_name || '',
      cooperating_brokerage_address:
        editData?.cooperating_brokerage_address || '',
      commission_percentage: editData?.commission_percentage || '',
      commission_payment_terms: editData?.commission_payment_terms || '',

      //Cooperating Broker
      cooperating_broker_name: editData?.cooperating_broker_name || '',
      cooperating_broker_title: editData?.cooperating_broker_title || '',
      cooperating_broker_phone: editData?.cooperating_broker_phone || '',
      cooperating_broker_email: editData?.cooperating_broker_email || '',

      // Agreement
      agreement_title: editData?.agreement_title || '',
      agreement_date: editData?.agreement_date || '',
      closing_date: editData?.closing_date || '',

      //
      termination_reason: editData?.termination_reason || '',
      clause_reference: editData?.clause_reference || '',
      due_diligence_deadline: editData?.due_diligence_deadline || '',
      document_list: editData?.document_list || '',

      earnest_money_amount: editData?.earnest_money_amount || '',
      escrow_agent_name: editData?.escrow_agent_name || '',
      escrow_company_name: editData?.escrow_company_name || '',

      buyer_execution_date_1: editData?.buyer_execution_date_1 || '',
      buyer_execution_date_2: editData?.buyer_execution_date_2 || '',
      seller_execution_date: editData?.seller_execution_date || '',
      date_executed: editData?.date_executed || '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (value) => {
      const body = {
        ...value,
        buyer: value?.buyer?._id,
        listing: value?.listing?._id,
        seller: value?.seller?._id,
        broker: value?.broker?._id,
        cooperating_broker_name: value?.cooperating_broker_name?._id,
        escrow_agent_name: value?.escrow_agent_name?._id,
        contentBase64: '',
        content: getAgreementContent(value?.content),
      };

      setSubmitting(true);

      body.contentBase64 = await convertToBase64(
        getAgreementContent(value?.content),
      );

      if (editData?._id) {
        const response = await Patch(
          `${baseApiUrl}/${editData?._id}`,
          body,
          apiHeader(accessToken),
        );

        if (response !== undefined) {
          if (value?.status === 'sent') {
            toast.success(`Contract Agreement Sent Successfully.`);
            navigate('/contract-sent');
          } else {
            toast.success(`Contract Agreement Edited Successfully.`);
            navigate('/contract-agreements');
          }

          setSubmitting(false);
          resetForm();
        }
      } else {
        const response = await Post(baseApiUrl, body, apiHeader(accessToken));

        if (response !== undefined) {
          if (value?.status === 'sent') {
            toast.success(`Contract Agreement Sent Successfully.`);
            navigate('/contract-sent');
          } else {
            toast.success(`Contract Agreement Created Successfully.`);
            navigate('/contract-agreements');
          }
          setSubmitting(false);
          resetForm();
        }
      }
    },
  });

  const getAllContractTemplates = async () => {
    if (accessToken && accessToken?.length > 0) {
      const url = BaseURL(`contract-template`);

      const response = await Get(url, accessToken);

      if (response?.status === 200 && response?.data?.result) {
        setContractTemplates(response?.data?.result);
      }
    }
  };

  const getUsers = async () => {
    if (accessToken && accessToken?.length > 0) {
      const url = BaseURL(
        `users/get-admins?roles=${encodeURIComponent(['seller'])}`,
      );

      const response = await Get(url, accessToken, companyId);

      if (response?.status === 200 && response?.data?.data) {
        const sellersData = response?.data?.data?.filter((user) =>
          user?.role?.includes('seller'),
        );

        setSellers(sellersData || []);
      }

      const buyerResponse = await Get(
        BaseURL(`users/get-admins?roles=buyer`),
        accessToken,
        companyId,
      );

      if (buyerResponse?.status === 200 && buyerResponse?.data?.data) {
        setBuyers(buyerResponse?.data?.data);
      }
    }
  };

  const get3PartyBroker = async () => {
    if (accessToken && accessToken?.length > 0) {
      const url = BaseURL(
        `users/get-admins?roles=${encodeURIComponent(['3rd-party-broker'])}`,
      );

      const response = await Get(url, accessToken, companyId);

      if (response?.status === 200 && response?.data?.data) {
        setCooperatingBroker(response?.data?.data);
      }
    }
  };

  const getBrokers = async () => {
    if (accessToken && accessToken?.length > 0) {
      const url = BaseURL(
        `users/get-admins?roles=${encodeURIComponent(['broker'])}`,
      );

      const response = await Get(url, accessToken, companyId);

      if (response?.status === 200 && response?.data?.data) {
        setBrokers(response?.data?.data);
      }
    }
  };

  const get3PartyContacts = async () => {
    if (accessToken && accessToken?.length > 0) {
      const url = BaseURL(
        `users/get-admins?roles=${encodeURIComponent(['3rd-party-contacts'])}`,
      );

      const response = await Get(url, accessToken, companyId);

      if (response?.status === 200 && response?.data?.data) {
        setThirdPartyContacts(response?.data?.data);
      }
    }
  };

  const getListing = async () => {
    const url = BaseURL(`business/admin/all`);

    const response = await Get(url, accessToken, companyId);

    if (response?.status === 200 && response?.data?.business) {
      setListings(response?.data?.business);
    }
  };

  const getEntities = async () => {
    const url = BaseURL(`entity`);

    const response = await Get(url, accessToken, companyId);

    if (response?.status === 200 && response?.data?.data) {
      setEntities(response?.data?.data);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getAllContractTemplates();
      getUsers();
      getBrokers();
      get3PartyBroker();
      get3PartyContacts();
      getListing();
      getEntities();
    }
  }, [accessToken]);

  // Handle Drag and Drop
  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    // If dropped outside the list or no movement
    if (!destination || source.index === destination.index) return;

    // Reorder items based on drag result
    const updatedItems = Array.from(values?.recipients);
    const [removed] = updatedItems.splice(source.index, 1); // Remove the dragged item
    updatedItems.splice(destination.index, 0, removed); // Insert the item in new position

    setFieldValue('recipients', updatedItems); // Update the state with the reordered items
  };

  return (
    <>
      <SideBarSkeleton>
        <div className={classes.container_main}>
          <div className={classes.main_heading}>
            <h4>{editData?._id ? 'Edit' : 'Add'} Contract Agreement</h4>
          </div>

          <form
            onSubmit={handleSubmit}
            className={classes.addEmailTemplateModal_main}
          >
            <Row className={classes.input_row}>
              {/* Contract Template select */}
              <FormControl
                fullWidth
                error={touched.type && Boolean(errors.type)}
              >
                <InputLabel id="type-label">Contract Template</InputLabel>
                <Select
                  labelId="type-label"
                  name="template"
                  value={values.template}
                  onChange={(e) => {
                    const value = e?.target?.value;
                    setFieldValue('template', value);
                    setFieldValue(
                      'content',
                      contractTemplates?.find((item) => item?._id == value)
                        ?.content,
                    );
                  }}
                  onBlur={handleBlur}
                  label="Contract Template"
                  MenuProps={{
                    style: {
                      maxHeight: '700px',
                      maxWidth: '400px',
                    },
                  }}
                >
                  {contractTemplates.map((trigger) => (
                    <MenuItem key={trigger._id} value={trigger._id}>
                      {trigger.name}
                    </MenuItem>
                  ))}
                </Select>

                {touched.type && errors.type && (
                  <FormHelperText>{errors.type}</FormHelperText>
                )}
              </FormControl>

              <Button
                type="button"
                className={classes.btn}
                label="Select Contract Field"
                onClick={() => {
                  setIsOpenContractInputFieldModal(
                    !isOpenContractInputFieldModal,
                  );
                }}
              />

              <div className={classes.var_section_main}>
                <div className={classes.var_section}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    Signing Order
                  </Typography>

                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          container
                          spacing={2}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {values?.recipients?.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  gap={8}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Typography
                                    variant="p"
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start',
                                      gap: '10px',
                                      fontSize: '18px',
                                      fontWeight: 400,
                                    }}
                                  >
                                    <FaRegUser size={20} />

                                    <span>
                                      {item?.name} ({item?.role})
                                    </span>
                                  </Typography>
                                </Grid>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>

              <div className={classes.textEditor}>
                <SunEditor
                  setOptions={{
                    buttonList: [
                      ['undo', 'redo'],
                      ['font', 'fontSize', 'formatBlock'],
                      [
                        'bold',
                        'underline',
                        'italic',
                        'strike',
                        'subscript',
                        'superscript',
                      ],
                      ['align', 'horizontalRule', 'list', 'table'],
                      ['fontColor', 'hiliteColor', 'textStyle', 'lineHeight'],
                      ['outdent', 'indent'],
                      ['removeFormat'],
                      ['link', 'image'],
                      // ['preview', 'print'],
                      // ['fullScreen', 'showBlocks', 'codeView'],
                    ],
                  }}
                  onChange={(value) => {
                    setFieldValue('content', value);
                  }}
                  defaultValue={values?.content}
                  height="470px"
                  setContents={values?.content}
                />
              </div>
            </Row>

            <div className={classes.btn_main}>
              <Button
                type="button"
                className={classes.btn}
                label="Preview Contract"
                disabled={!values?.content?.trim()}
                onClick={() => {
                  setIsOpenContractModal(!isOpenContractModal);
                }}
              />

              <Button
                type="submit"
                className={classes.btn}
                label={
                  isSubmitting && values?.status === 'draft'
                    ? 'Submitting...'
                    : 'Save'
                }
                disabled={isSubmitting && values?.status === 'draft'}
                onClick={() => {
                  setFieldValue('status', 'draft');
                }}
              />

              <Button
                type="submit"
                className={classes.btn}
                label={
                  isSubmitting && values?.status === 'sent'
                    ? 'Submitting...'
                    : 'Send For Signature'
                }
                disabled={isSubmitting && values?.status === 'sent'}
                onClick={() => {
                  setFieldValue('status', 'sent');
                }}
              />
            </div>
          </form>
        </div>

        <ModalSkeleton
          show={isOpenContractModal}
          setShow={setIsOpenContractModal}
          borderRadius="20px"
          width="1340px"
          borderLine={false}
          header={'Contract Agreement Preview'}
          showCloseIcon={true}
        >
          <style jsx>{`
            ..table_row table {
              border-collapse: collapse;
            }

            .table_row th,
            td {
              border-width: 1px;
            }
          `}</style>

          <div
            dangerouslySetInnerHTML={{
              __html: getAgreementContent(values?.content),
            }}
            style={{
              maxHeight: '80dvh',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            className={classes?.table_row}
          />
        </ModalSkeleton>

        <ContractInputFieldModal
          isOpen={isOpenContractInputFieldModal}
          setIsOpen={setIsOpenContractInputFieldModal}
          editData={values}
          listings={listings}
          entities={entities}
          sellers={sellers?.map((user) => ({
            name: `${user?.firstName || ''} ${user?.lastName}`,
            ...user,
          }))}
          buyers={buyers?.map((user) => ({
            name: `${user?.firstName || ''} ${user?.lastName}`,
            ...user,
          }))}
          cooperatingBroker={cooperatingBroker?.map((user) => ({
            name: `${user?.firstName || ''} ${user?.lastName}`,
            ...user,
          }))}
          thirdPartyContacts={thirdPartyContacts?.map((user) => ({
            name: `${user?.firstName || ''} ${user?.lastName}`,
            ...user,
          }))}
          brokers={brokers?.map((user) => ({
            name: `${user?.firstName || ''} ${user?.lastName}`,
            ...user,
          }))}
          handleOnSubmit={(newData) => {
            setValues({
              ...values,
              ...newData,
            });
          }}
        />
      </SideBarSkeleton>
    </>
  );
};

export default AddContractAgreement;
