export const rolesOptions = [
  { label: 'Finance', value: 'finance' },
  { label: 'HR', value: 'hr' },
  { label: 'Executive', value: 'executive' },
  { label: 'Sales', value: 'sales' },
  { label: 'Buyer', value: 'buyer' },
  { label: 'Seller', value: 'seller' },
  { label: 'Banker', value: 'banker' },
  { label: 'Attorney', value: 'attorney' },
  { label: '3rd Party Broker', value: '3rd-party-broker' },
  { label: 'Landlord', value: 'landlord' },
  { label: 'Potential Seller', value: 'potential-seller' },
  { label: 'Accountant', value: 'accountant' },
  { label: 'Job Applicant', value: 'job-applicant' },
  { label: 'Title Company', value: 'title-company' },
  { label: '3rd Party Contact', value: '3rd-party-contacts' },
  { label: 'Service Provider', value: 'service-provider' },
  { label: 'Admin', value: 'admin' },
  { label: 'Broker', value: 'broker' },
];

export const adminRoles = [
  'super-admin',
  'admin',
  'broker',
  'hr',
  'executive',
  'finance',
  'sales',
];

export const outSideUsersOptionsForFilter = [
  { label: 'All', value: 'outside-roles' },
  { label: 'Landlord', value: 'landlord' },
  { label: 'Attorney', value: 'attorney' },
  { label: 'Accountant', value: 'accountant' },
  { label: 'Job Applicant', value: 'job-applicant' },
  { label: 'Title Company', value: 'title-company' },
  { label: '3rd Party Contacts', value: '3rd-party-contacts' },
  { label: '3rd Party Brokers', value: '3rd-party-brokers' },
  { label: 'Service Provider', value: 'service-provider' },
  { label: 'Banker', value: 'banker' },
  { label: 'Potential Seller', value: 'potential-seller' },
];

export const adminUsersOptionsForFilter = [
  { label: 'All', value: 'admin-roles' },
];

export const emailTemplateOptions = [
  { label: 'Custom Email', value: 'custom' },
  { label: 'Newsletter Template', value: 'newsletter' },
  { label: 'Blast Template', value: 'blast' },
];

export const leadSourceOptions = [
  { label: 'BNI', value: 'bni' },
  { label: 'Referral', value: 'referral' },
  { label: 'Website', value: 'website' },
  { label: 'Repeat Customer', value: 'repeat-customer' },
  { label: 'Other Networking', value: 'other-networking' },
  { label: 'Third Party', value: 'third-party' },
  { label: 'Other Brokers', value: 'other-brokers' },
  { label: 'Personal Prospecting', value: 'personal-prospecting' },
  { label: '3rd Party Cold Call', value: '3rd-party-cold-call' },
  { label: 'BBS Cold', value: 'bbs-cold' },
  { label: 'Other', value: 'other' },
];

export const listingStatusOptions = [
  { label: 'Inquired', value: 'inquired' },
  { label: 'NDA-Submitted', value: 'nda-submitted' },
  { label: 'NDA-Signed', value: 'nda-signed' },
  { label: 'NDA-Approved', value: 'nda-approved' },
  { label: 'Under-Negotiation', value: 'under-negotiation' },
  { label: 'Under-Contract', value: 'under-contract' },
  { label: 'Sold', value: 'sold' },
  { label: 'Not-Interested', value: 'not-interested' },
  { label: 'Not-Qualified', value: 'not-qualified' },
  { label: 'VIP', value: 'VIP' },
  { label: 'Closed', value: 'closed' },
];

export const leadProgressOptions = [
  { label: 'New', value: 'new' },
  { label: 'Called', value: 'called' },
  { label: 'Emailed', value: 'emailed' },
  { label: 'Not interested', value: 'not-interested' },
  { label: 'Not qualified', value: 'not-qualified' },
  { label: 'Ref to broker', value: 'ref-to-broker' },
  { label: 'Under Negotiation', value: 'under-negotiation' },
  { label: 'Under Contract', value: 'under-contract' },
];

export const leadProgressColors = {
  'new': { background: 'white', color: 'black' },
  'called': { background: '#ffe082', color: '#5d4037' },
  'emailed': { background: '#ffa5003b', color: 'orange' },
  'not-interested': { background: '#ffccbc', color: '#d32f2f' },
  'not-qualified': { background: '#e57373', color: '#fff' },
  'ref-to-broker': { background: '#81d4fa', color: '#003f3f' },
  'under-negotiation': { background: '#c8e6c9', color: '#388e3c' },
  'under-contract': { background: '#388e3c', color: '#fff' },
};

export const groupReferenceArray = [
  { label: 'group', value: 'group' },
  { label: 'lead-group', value: 'lead-group' },
  { label: 'business-group', value: 'business-group' },
  { label: 'guest', value: 'guest' },
];

export const leadStatusOptions = [
  { label: 'VIP', value: 'VIP' },
  { label: 'Inquired', value: 'inquired' },
  { label: 'NDA-Submitted', value: 'nda-submitted' },
  { label: 'NDA-signed', value: 'nda-signed' },
  { label: 'Under-Negotiation', value: 'under-negotiation' },
  { label: 'Under-Contract', value: 'under-contract' },
  { label: 'Sold', value: 'sold' },
  { label: 'Not-Interested', value: 'not-interested' },
  { label: 'Not-Qualified', value: 'not-qualified' },
];

export const onlinePresenceOptions = [
  { label: 'Facebook', value: 'facebook' },
  { label: 'Google Map', value: 'google-map' },
  { label: 'Yelp', value: 'yelp' },
  { label: 'MapQuest', value: 'map-quest' },
  { label: 'Website', value: 'website' },
  { label: 'Uber Eats', value: 'uber-eats' },
  { label: 'Instagram', value: 'instagram' },
  { label: 'Bing location', value: 'bing-location' },
];

export const createListingstatusOptions = [
  { label: 'Prospect', value: 'prospect' },
  { label: 'Broker-Review', value: 'broker-review' },
  { label: 'Seller-Review', value: 'seller-review' },
  { label: 'Pocket', value: 'pocket' },
  { label: 'Published', value: 'published' },
  { label: 'Under-Contract', value: 'under-contract' },
  { label: 'Sold', value: 'sold' },
  { label: 'Lost', value: 'lost' },
  { label: 'Off-Market', value: 'off-market' },
];
export const amountTypeOptions = [
  {
    label: 'Owned',
    value: 'real-estate',
  },
  {
    label: 'Leased',
    value: 'rent',
  },
  {
    label: 'None',
    value: 'location',
  },
];

export const filterConversationOptions = [
  {
    label: 'All Conversations',
    value: 'all-conversation',
  },
  {
    label: 'Sent to me',
    value: 'sent-to-me',
  },
];

export const todoTypeOptions = [
  { label: 'Note', value: 'note' },
  { label: 'Call', value: 'call' },
  { label: 'Agenda', value: 'agenda' },
  { label: 'Email', value: 'email' },
  { label: 'Schedule a meeting', value: 'schedule a meeting' },
  { label: 'Other', value: 'other' },
];

export const todoImportanceOptions = [
  { label: 'Moderate', value: 'green' },
  { label: 'Urgent', value: 'red' },
  { label: 'High priority', value: 'lemon' },
  { label: 'Low priority', value: 'gray' },
];

export const mediaFileExtensions = [
  'pdf',
  'heic',
  'heics',
  'docx',
  'doc',
  'txt',
  'xlsx',
  'xls',
  'csv',
  'mp4',
  'mkv',
  'avi',
  'mp3',
];

export const imageFileExtensions = ['png', 'jpeg', 'jpg', 'webp',
  'gif',];
