import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { apiHeader, BaseURL } from '../config/apiUrl';
import { Post } from '../Axios/AxiosFunctions';
import { useSelector, useDispatch } from 'react-redux';
import { setLogo, setLogoLoading } from '../store/company/companySlice';

export const LogoProvider = ({ children }) => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state?.companyReducer?.loading);
    const existingLogo = useSelector(state => state?.companyReducer?.logo);

    useEffect(() => {
        const fetchLogo = async () => {
            if (existingLogo) {
                return;
            }

            try {
                dispatch(setLogoLoading(true));
                const url = BaseURL('company/logo');
                const headers = apiHeader();
                const response = await Post(url, {}, headers);
                const logoUrl = response?.data?.logoUrl || '';
                dispatch(setLogo(logoUrl));
            } catch (error) {
                console.error('Error fetching logo:', error);
            } finally {
                dispatch(setLogoLoading(false));
            }
        };

        fetchLogo();
    }, [dispatch, existingLogo]);

    if (loading) {
        return (
            <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100vh' 
            }}>
                <CircularProgress />
            </div>
        );
    }

    return children;
};
