import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Button } from '../../Component/Button/Button';
import { Input } from '../../Component/Input/Input';
import ModalSkeleton from '../ModalSkeleton';
import classes from './AddOrUpdateCompanyProfileModal.module.css';
import { ProfileWithEditButton } from '../../Component/ProfileWithEditButton';
import { Checkbox } from '../../Component/Checkbox/Checkbox';

const AddOrUpdateCompanyProfileModal = ({
  show,
  setShow,
  handleSubmit,
  data,
  isLoading = false,
}) => {
  useEffect(() => {
    if (data !== undefined) {
      setName(data?.name);
      setWebsite(data?.website ?? '');
      setEmailDomain(data?.emailDomain ?? '');
      setAddress(data?.address ?? '');
      setDomainNames(data?.allowedDomains ?? []);
      setPhoto(data?.logoUrl ?? '');
      setPhone(data?.phone ?? '');
      setIsPaidClient(data?.isPaidClient ?? false);
    }
  }, [data]);
  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [emailDomain, setEmailDomain] = useState('');
  const [address, setAddress] = useState('');
  const [domainName, setDomainName] = useState('');
  const [domainNames, setDomainNames] = useState([]);
  const [photo, setPhoto] = useState('');
  const [phone, setPhone] = useState('');
  const [isPaidClient, setIsPaidClient] = useState(false);

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return phoneRegex.test(phoneNumber);
  };

  const HandleSubmitData = async () => {
    if (name == '') {
      return toast.error(`Client Name is required`);
    }
    if (website == '') {
      return toast.error(`Client Website is required`);
    }
    if (emailDomain == '') {
      return toast.error(`Client Email Domain is required`);
    }
    if (address == '') {
      return toast.error(`Client Address is required`);
    }
    if (domainNames?.length == 0) {
      return toast.error(`Allowed Domains is required`);
    }
    if (phone == '') {
      return toast.error(`Phone number is required`);
    }
    if (!validatePhoneNumber(phone)) {
      return toast.error(`Please enter a valid US phone number`);
    }
    if (photo == '') {
      return toast.error(`Client Logo is required`);
    }

    let body = {
      name,
      website,
      emailDomain,
      address,
      allowedDomains: domainNames,
      photo,
      phone,
      isPaidClient
    };
    await handleSubmit(body);
    setName('');
    setWebsite('');
    setEmailDomain('');
    setAddress('');
    setDomainName('');
    setDomainNames([]);
    setPhoto('');
    setPhone('');
    setIsPaidClient(false);
  };

  return (
    <div>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        width="700px"
        borderRadius="20px"
        header={`${data == undefined ? 'Add' : 'Edit'} Company Profile`}
      >
        <div className={classes.container}>
          <Row className={classes.row} style={{ rowGap: '0' }}>
            <label className="input_labelText__sc8Kx false" style={{ textAlign: 'center' }}>Company Logo</label>
            <Col md={12} className={[classes.imgCol, classes.mb12]} style={{ display: 'flex', justifyContent: 'center' }}>
              <ProfileWithEditButton
                updateImage={photo}
                setUpdateImage={setPhoto}
                onClick={() => {
                  window.open(
                    typeof photo == 'object'
                      ? URL.createObjectURL(photo)
                      : imageUrl(photo),
                    '_blank',
                  );
                }}
                isEdit={true}
              />
            </Col>
          </Row>
          <Row className={classes.row}>
            <Col md={12} className={classes.mb12}>
              <Input
                setter={setName}
                value={name}
                placeholder={'Client Name'}
                label={'Client Name'}
              />
            </Col>
          </Row>
          <Row className={classes.row}>
            <Col md={6} className={classes.mb6}>
              <Input
                setter={setWebsite}
                value={website}
                placeholder={'Client Website'}
                label={'Client Website'}
              />
            </Col>
            <Col md={6} className={classes.mb6}>
              <Input
                setter={setEmailDomain}
                value={emailDomain}
                placeholder={'Client Email Domain'}
                label={'Client Email Domain'}
              />
            </Col>
          </Row>
          <Row className={classes.row}>
            <Col md={12} className={classes.mb12}>
              <Input
                setter={setAddress}
                value={address}
                placeholder={'Client Address'}
                label={'Client Address'}
              />
            </Col>
          </Row>
          <Row className={`${classes.row} ${classes.mb12}`}>
            <Col md={8}>
              <Input
                setter={setPhone}
                value={phone}
                placeholder={'Phone Number (e.g. 123-456-7890)'}
                label={'Phone Number'}
              />
            </Col>
            <Col md={4} style={{ display: 'flex', alignItems: 'center'}}>
              <Checkbox
                value={isPaidClient ? 'Paid Client' : ''}
                setValue={(e) => setIsPaidClient(e === 'Paid Client')}
                label={'Paid Client'}
              />
            </Col>
            </Row>
          <Row className={classes.row}>
            <Col md={12}>
              <div className={classes.mb12}>
                <div style={{ display: 'flex', gap: '10px', marginBottom: '10px', alignItems: 'end', width: '100%' }}>
                  <Input
                    label={'Allowed Domains'}
                    value={domainName}
                    setter={setDomainName} 
                    placeholder="Enter domain name"
                    parentCustomStyle={{ width: '100%' }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && domainName.trim()) {
                        setDomainNames([...domainNames, domainName.trim()]);
                        setDomainName('');
                      }
                    }}
                  />
                  <Button
                    label="Add"
                    onClick={() => {
                      if (domainName.trim()) {
                        setDomainNames([...domainNames, domainName.trim()]);
                        setDomainName('');
                      }
                    }}
                    style={{ width: '100px', height: '54px' }}
                  />
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                  {domainNames.map((domain, index) => (
                    <div
                      key={index}
                      style={{
                        background: '#f0f0f0',
                        padding: '5px 10px',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px'
                      }}
                    >
                      <span>{domain}</span>
                      <button
                        onClick={() => {
                          const newDomains = [...domainNames];
                          newDomains.splice(index, 1);
                          setDomainNames(newDomains);
                        }}
                        style={{
                          border: 'none',
                          background: 'none',
                          cursor: 'pointer',
                          padding: '0 0 0 5px',
                          color: '#999'
                        }}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
          <div className={classes.btn_main}>
            <Button
              onClick={() => HandleSubmitData()}
              className={classes.btn}
              label={
                isLoading
                  ? 'Submitting...'
                  : data == null
                    ? 'Add Company Profile'
                    : 'Edit Company Profile'
              }
              disabled={isLoading}
            />
          </div>
        </div>
      </ModalSkeleton>
    </div>
  );
};

export default AddOrUpdateCompanyProfileModal;
