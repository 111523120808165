import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TreeItem from '@material-ui/lab/TreeItem';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Col, Container, Row } from 'react-bootstrap';
import { CiMenuKebab } from 'react-icons/ci';
import { RiFolderOpenFill } from 'react-icons/ri';
import {
  MdContentCopy,
  MdContentCut,
  MdContentPaste,
  MdDelete,
  MdDownload,
  MdEdit,
  MdFolderOpen,
  MdUploadFile,
} from 'react-icons/md';
import { toast } from 'react-toastify';

import {
  imageFileExtensions,
  mediaFileExtensions,
} from '../../constant/commonData';
import { apiHeader, mediaUrl, imageUrl, BaseURL } from '../../config/apiUrl';
import { moment } from '../../utils/timeUtils';
import { Get, Patch, Post } from '../../Axios/AxiosFunctions';
import useDebounce from '../../CustomHooks/useDebounce';
import SearchInput from '../../Component/SearchInput';
import { Checkbox } from '../../Component/Checkbox/Checkbox';
import Loader from '../../Component/Loader';
import { Button } from '../../Component/Button/Button';
import CreateFolderModal from '../../modals/CreateFolderModal';
import AreYouSureModal from '../../modals/AreYouSureModal';
import { IconByExtension } from './IconByExtension';
import classes from './ViewTeamFolderDetail.module.css';

const NoFolderComp = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={classes.noFolderComp}>
        <RiFolderOpenFill />
        <p>Drag + Drop Files Here</p>
      </div>
    </div>
  );
};

const ListingFolderDetail = ({ folderRoot, business }) => {
  const id = useParams()?.id || folderRoot;
  const listingId = useParams()?.businessId || business;
  const inputRef = useRef(null);

  const { user, access_token: accessToken, company_id: companyId } = useSelector(
    (state) => state.authReducer,
  );

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState('');
  const [isOpenModal, setIsOpenModal] = useState('');
  const [reloading, setReloading] = useState(false);
  const [action, setAction] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const [search, setSearch] = useState('');
  const debouncedSearchTerm = useDebounce(search, 500);

  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [mainItems, setMainItems] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [files, setFiles] = useState([]);

  const [isFolderDownloadValid, setIsFolderDownloadValid] = useState(false);
  const [isValidateCreate, setIsValidateCreate] = useState(false);
  const [isValidateHandle, setIsValidateHandle] = useState(false);
  const [isValidatePaste, setIsValidatePaste] = useState(false);
  const [isValidateDownload, setIsValidateDownload] = useState(false);

  const [buyer, setBuyer] = useState('');

  const [tabValue, setTabValue] = useState('');

  const onTabChange = (event, newValue) => {
    if (items?.length) {
      const item = items?.find(
        (ele) =>
          ele?.name == newValue && items[0]?.children?.includes(ele?._id),
      );
      setSelectedItem(item);
      setSelectedFolder(item);
    }
    setTabValue(newValue);
  };

  // create folder
  const createFolder = async (e) => {
    if (selectedItem == null || selectedItem?.isFile) return;
    const url = BaseURL('data-room');
    let params = {
      ...e,
      parent: selectedItem?._id,
    };
    setLoading(true);

    const response = await Post(url, params, apiHeader(accessToken), companyId);
    if (response !== undefined) {
      toast.success('Folder created successfully');
      setShow('');
      setReloading(!reloading);
    }
    setLoading(false);
  };

  // create folder
  const updateData = async ({ name }) => {
    if (selectedItem == null) return;

    const url = BaseURL('data-room/update');
    let params = {
      folderId: selectedItem?._id,
      ...(selectedItem?.isFile ? { fileName: name } : { name }),
    };
    setLoading(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setLoading(false);
    if (response) {
      toast.success('Successfully changed');
      setShow('');
      setReloading(!reloading);
    }
  };

  // create file
  const uploadFile = async (files, node = selectedFolder) => {
    setSelectedFolder(node);
    setSelectedItem(node);
    files = Array.from(files);
    if (files?.length == 0) return toast.error('File is reuqired');
    for (var i = 0; files[i]; i++)
      if (files[i]?.type == 'mp4') return toast.warn('Invalid file type');

    const url = BaseURL('data-room');
    let params = {
      file: files?.map((ite) => ite),
      parent: node?._id,
    };

    const formData = new FormData();
    // e?.roles?.map((item) => formData.append('roles[]', item));
    params?.file?.map((item) => formData.append('file', item));
    formData.append('parent', params?.parent);
    setLoading(true);
    const response = await Post(url, formData, apiHeader(accessToken), companyId);
    if (response !== undefined) {
      toast.success('File upload successfully');
      setShow('');
      setReloading(!reloading);
    }
    setLoading(false);
  };

  const handleItemSelect = (node, items) => {
    let filtered = [];
    if (selectedItems.findIndex((item) => item?._id == node?._id) == -1) {
      filtered = [
        ...mainItems?.filter((item) => item?.parent == node?.parent),
        node,
      ];
      setMainItems(filtered);
    } else {
      if (selectedItems?.findIndex((item) => item?._id == node?.parent) != -1)
        return;

      const index = mainItems?.findIndex((item) => item?._id == node?._id);
      if (index != -1) {
        filtered = [...mainItems];
        filtered?.splice(index, 1);
        setMainItems(filtered);
      }
    }

    const selected = [];
    filtered?.forEach((root) => {
      selected.push(
        ...items?.filter((item) => item?.parents?.includes(root?._id)),
        root,
      );
    });
    setSelectedItems(selected);
  };

  const handleCopyClicked = () => {
    setAction('copy');
    toast.success('Selected items to copy', { autoClose: 1000 });
  };

  const handleCutClicked = () => {
    setAction('cut');
    toast.success('Selected items to cut', { autoClose: 1000 });
  };

  const handlePaste = async () => {
    const url = BaseURL(action == 'copy' ? 'data-room/copy' : 'data-room/cut');
    const parent = selectedItem?._id;
    const params = { parent, items: mainItems.map((item) => item?._id) };
    setLoading(true);
    const response = await Post(url, params, apiHeader(accessToken), companyId);
    if (response !== undefined) {
      toast.success('Success', { autoClose: 1500 });
    }
    setLoading(false);
    setReloading(!reloading);
  };

  const handleDeleteFolders = async () => {
    const parent = mainItems[0]?.parent;
    const params = { parent, items: mainItems };
    setIsOpenModal('');
    setLoading(true);
    const response = await Post(
      BaseURL('data-room/delete-items'),
      params,
      apiHeader(accessToken),
      companyId
    );
    if (response !== undefined) {
      toast.success('Deleted Successfully', { autoClose: 1500 });
    }
    setLoading(false);
    setReloading(!reloading);
  };

  const processData = (folders, node) => {
    let result = [node];
    node?.children?.forEach((child) => {
      const index = folders?.findIndex((folder) => folder?._id == child);
      if (index == -1) return;
      const d = {
        ...folders[index],
        parent: node?._id,
        parents: [...node?.parents, node?._id],
      };
      result = [...result, ...processData(folders, d)];
    });
    return result;
  };

  const getData = async () => {
    const url = BaseURL(`data-room/business/${listingId}/all`);
    const response = await Get(url, accessToken, companyId);

    if (response != undefined) {
      const folders = response?.data?.data;

      console.log(' folders=> ', folders);
      const index = folders?.findIndex((folder) => folder?._id == id);
      if (index == -1) return;

      const result = processData(folders, {
        ...folders[index],
        parent: '0',
        parents: ['0'],
      });
      console.log(' processed folders=> ', result);
      !tabValue && result?.length > 1 && setTabValue(result[1]?.name);

      setData(result);
      setSelectedItems([]);
      setMainItems([]);
    }
  };

  useEffect(() => {
    getData();
  }, [listingId, reloading]);

  useEffect(() => {
    validateCreate();
    validateHandle();
    validatePaste();
    validateDownload();
  }, [selectedItem, selectedItems]);

  useEffect(() => {
    setFiles(
      items.filter(
        (item) => item?.isFile && item?.parent == selectedFolder?._id,
      ),
    );
  }, [selectedFolder, items]);

  useEffect(() => {
    if (!expanded.length)
      setExpanded(
        items
          ?.filter((item) => !item?.isFile && item?.parents?.length < 2)
          .map((item) => item?._id),
      );
    if (!selectedFolder && items.length) {
      setSelectedFolder(items[0]);
    }
  }, [items]);

  useEffect(() => {
    const reg = new RegExp(search, 'i');
    const filtered =
      data?.filter((item) =>
        reg.test(item?.isFile ? item?.fileName : item?.name),
      ) || [];

    const ids = new Set(filtered.map((item) => item?._id));
    filtered.forEach((item) => {
      item?.parents?.forEach((it) => {
        ids.add(it);
      });
    });

    setItems(
      data?.filter(
        (item) => ids.has(item?._id) || item?.parents?.length == 1,
      ) || [],
    );
  }, [data, debouncedSearchTerm]);

  useEffect(() => {
    const b = data?.findIndex((item) => item?.name == 'buyer');
    if (b != -1) setBuyer(data[b]?._id);
  }, [data]);

  useEffect(() => {
    if (files?.length > 0) {
      console.log(files)
      if (files[0]?.parent == selectedItem?._id) {
        setIsFolderDownloadValid(true);
      } else {
        setIsFolderDownloadValid(false);
      }
    } else {
      setIsFolderDownloadValid(false);
    }
  }, [selectedItem, files]);

  const getIcon = (node) => {
    let fileExtension = 'folder';
    if (node?.isFile) fileExtension = node?.name?.split('.').pop();

    return <IconByExtension extension={fileExtension} />;
  };

  const isEditable = (node = selectedItem) => {
    const index = items?.findIndex((item) => node?.parent == item?._id);
    if (index == -1) return false;

    const rIDs = [...(node?.parents ? node?.parents : []), node?._id];
    const f = rIDs.includes(buyer);
    return f ? node?.parents.length > 3 : node?.parents.length > 2;
  };

  const validateCreate = (node = selectedItem, flag = true) => {
    const rIDs = [...(node?.parents ? node?.parents : []), node?._id];
    let ret = false;
    const f = rIDs.includes(buyer);
    ret =
      !node?.isFile &&
      (f ? node?.parents.length > 2 : node?.parents.length > 1);
    flag && setIsValidateCreate(!!ret);
    return ret;
  };

  const validateHandle = (node = selectedItem) => {
    const index = items?.findIndex((item) => node?.parent == item?._id);
    let ret = false;
    if (index != -1) {
      const rIDs = [...(node?.parents ? node?.parents : []), node?._id];
      const f = rIDs.includes(buyer);
      ret = f ? node?.parents.length > 3 : node?.parents.length > 2;
    }
    setIsValidateHandle(!!ret);
    return ret;
  };

  const validatePaste = (node = selectedItem) => {
    const rIDs = [...(node?.parents ? node?.parents : []), node?._id];
    const f = rIDs.includes(buyer);
    const ret =
      !node?.isFile &&
      (f ? node?.parents.length > 2 : node?.parents.length > 1) &&
      selectedItems?.length &&
      selectedItems?.findIndex((item) => item?._id == node?._id) == -1;
    setIsValidatePaste(!!ret);
    return ret;
  };

  const validateDownload = (node = selectedItem) => {
    const ret = node?.isFile;
    setIsValidateDownload(ret);
  };

  const onSelectItem = (event, node) => {
    event.stopPropagation();
    handleItemSelect(node, items);
  };

  const onClickMenu = (event, node) => {
    setSelectedItem(node);
    event?.stopPropagation();
  };

  const onCreateFolder = async () => {
    setAnchorEl(null);
    setShow('folder');
  };

  const onUploadFile = async () => {
    setAnchorEl(null);
    setSelectedFolder(selectedItem);
    inputRef.current.click();
  };

  const onRename = async () => {
    setAnchorEl(null);
    setShow('rename');
  };

  const onCopyItem = () => {
    setAnchorEl(null);

    if (!selectedItems.some((item) => item?._id == selectedItem?._id))
      handleItemSelect(selectedItem, items);

    handleCopyClicked();
  };

  const onCutItem = () => {
    setAnchorEl(null);

    if (!selectedItems.some((item) => item?._id == selectedItem?._id))
      handleItemSelect(selectedItem, items);

    handleCutClicked();
  };

  const onPasteItem = async () => {
    setAnchorEl(null);
    handlePaste();
  };

  const onDeleteItem = async () => {
    setAnchorEl(null);

    if (!selectedItems.some((item) => item?._id == selectedItem?._id))
      handleItemSelect(selectedItem, items);

    setIsOpenModal('deleting');
  };

  const onDrop = (event, node = selectedFolder) => {
    event.preventDefault();
    if (
      !node?.isFile &&
      [node?._id, ...(node?.parents ? node?.parents : [])].includes(buyer)
        ? node?.parents?.length > 2
        : node?.parents?.length > 1
    ) {
      uploadFile(event.dataTransfer.files, node);
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const downloadFolder = async (files) => {
    try {
      setAnchorEl(null);
      const fileKeys = files.map(node => node.name).join(',');
      
      const downloadUrl = BaseURL(`download-zip?keys=${fileKeys}`);
      
      const response = await fetch(downloadUrl);
      
      if (!response?.ok) {
        toast.error('Download failed');
        return;
      }
      
      const blob = await response.blob();
      
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${selectedItem?.name || `download-${Date.now()}`}.zip`;
      
      document.body.appendChild(a);
      a.click();
      
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      toast.success('Download folder successfully');
    } catch (error) {
      console.error('Error downloading files:', error);
      throw error;
    }
  };

  const listItem = (node) => {
    const onSource = () => {
      if (!node?.isFile) return;
      const extension = node?.name?.split('.')[1]?.toLowerCase();
      if (mediaFileExtensions?.includes(extension)) {
        window.open(`${mediaUrl(node?.name)}`);
      } else if (imageFileExtensions.includes(extension)) {
        window.open(`${imageUrl(node?.name)}`);
      } else return;
    };

    return (
      <div
        className={classes.itemsContainer}
        onDrop={(event) => {
          !node?.isFile && onDrop(event, node);
        }}
        onDragOver={onDragOver}
      >
        <Checkbox
          className={classes.checkbox}
          value={
            selectedItems.findIndex((item) => item?._id == node?._id) != -1
              ? ' '
              : ''
          }
          label={' '}
          setValue={(value, event) => onSelectItem(event, node)}
          disabled={!isEditable(node)}
          key={node?._id}
        />
        {getIcon(node)}
        <div className={classes.descContainer}>
          <div
            className={[
              classes.descName,
              node?.isFile && classes.fileName,
            ].join(' ')}
            onClick={() => node?.isFile && onSource()}
          >
            {node?.isFile ? node?.fileName : node?.name}
          </div>
          <div className={classes.descDate}>
            🕑{moment(node?.updatedAt).format('MM-DD-YYYY hh:mm')}
          </div>
        </div>
        {node?.parent != '0' && (
          <CiMenuKebab
            size={20}
            className={classes.menuIcon}
            onClick={(event) => {
              onClickMenu(event, node);
              setAnchorEl(anchorEl ? null : event.currentTarget);
            }}
          />
        )}
      </div>
    );
  };

  const findParents = (ele) => {
    if (!ele.owner) return [ele];

    const index = items?.findIndex((e) => ele.owner == e._id);
    if (index < 0) return [];
    return [...findParents(items[index]), ele];
  };

  const renderTree = (node) => {
    let children = [];
    if (!node?.isFile)
      children = items.filter(
        (item) => node.children.includes(item?._id) && !item?.isFile,
      );

    return (
      <TreeItem
        key={node._id}
        nodeId={node._id}
        label={listItem(node)}
        onClick={() => {
          setSelectedFolder(node);
          setSelectedItem(node);
        }}
      >
        {!children.length && !node.isFile ? (
          <></>
        ) : (
          children.map((ele) => renderTree(ele))
        )}
      </TreeItem>
    );
  };

  const toDataURL = (url) => {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  };

  async function download(node) {
    const url = mediaFileExtensions.includes(node?.name?.split('.')[1])
      ? `${mediaUrl(node?.name)}`
      : `${imageUrl(node?.name)}`;
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = await toDataURL(url);
    a.download = node?.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <>
      <style>
        {folderRoot
          ? `
          .listScroll {
            height: calc(100vh - 400px);
          }`
          : `
          .listScroll {
            height: calc(100vh - 222px);
          }` +
            `
          .MuiTreeItem-label {
            width: calc(100% - 41px)! important;
          }`}
      </style>
      <div className={[classes.mainContainer]}>
        <div className={[classes.headerContainer]}>
          <Row>
            <Col md={8} sm={12}>
              <h3>{items[0]?.business?.companyName}</h3>
            </Col>
            <Col md={4} sm={12}>
              <SearchInput
                setter={(e) => {
                  setSearch(e);
                }}
                value={search}
                placeholder={'Search'}
                customStyle={{ width: '100%' }}
              />
            </Col>
          </Row>
        </div>
      </div>
      <Container fluid className={classes.mainBody}>
        {loading ? (
          <Loader />
        ) : items?.length == 0 ? (
          <></>
        ) : (
          <Row>
            <Col lg={6} className={classes.treeContainer}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={onTabChange}
                    aria-label="Project & Task Tab"
                  >
                    {items[0]?.children
                      ?.map((node) => {
                        const item = items.find((ele) => ele?._id == node);
                        if (!item) return null;
                        return <Tab label={item?.name} value={item?.name} />;
                      })
                      .filter((node) => node)}
                  </TabList>
                </Box>
                {items[0]?.children
                  ?.map((node) => {
                    const item = items.find((ele) => ele?._id == node);
                    if (!item) return null;
                    return (
                      <TabPanel
                        value={item?.name}
                        style={{ padding: '25px 0 0 0' }}
                      >
                        <div
                          className={classes.treeviewContainer}
                          style={
                            folderRoot
                              ? {
                                  height: 'calc(100vh - 477px)',
                                }
                              : {
                                  height: 'calc(100vh - 302px)',
                                }
                          }
                        >
                          <TreeView
                            defaultCollapseIcon={
                              <ExpandMoreIcon style={{ fontSize: '1.5em' }} />
                            }
                            defaultExpandIcon={
                              <ChevronRightIcon style={{ fontSize: '1.5em' }} />
                            }
                            className={classes.treeview}
                            expanded={expanded}
                            onNodeToggle={(event, ids) => setExpanded(ids)}
                            selected={selectedFolder?._id}
                          >
                            {renderTree(item)}
                          </TreeView>
                        </div>
                      </TabPanel>
                    );
                  })
                  .filter((node) => node)}
              </TabContext>
            </Col>
            <Col lg={6}>
              <div
                className={classes.listBody}
                onDrop={onDrop}
                onDragOver={onDragOver}
              >
                <div className={classes.uploadContainer}>
                  <Button
                    label={'Upload Files'}
                    disabled={!validateCreate(selectedFolder, false)}
                    className={classes.submit_btn}
                    onClick={() => inputRef.current.click()}
                  />
                  <input
                    hidden
                    multiple
                    type={'file'}
                    ref={inputRef}
                    onChange={(e) => uploadFile(e.target.files)}
                  />
                  {files?.length > 0 && <div>Drag + Drop Files Here</div>}
                </div>
                <div
                  className={classes.listContainer}
                  style={
                    folderRoot
                      ? {
                          height: 'calc(100vh - 477px)',
                        }
                      : {
                          height: 'calc(100vh - 302px)',
                        }
                  }
                >
                  {files?.length ? (
                    files?.map((node) => listItem(node))
                  ) : (
                    <NoFolderComp />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
      {Boolean(anchorEl) && (
        <Menu
          id="demo-customized-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
        >
          {!isValidateCreate &&
            !isValidateHandle &&
            !isValidatePaste &&
            !isValidateDownload && (
              <MenuItem className={classes.menuItem}>...</MenuItem>
            )}
          {isValidateCreate && (
            <MenuItem className={classes.menuItem} onClick={onCreateFolder}>
              <MdFolderOpen className={classes.mIcons} /> Create Folder
            </MenuItem>
          )}
          {isValidateCreate && (
            <MenuItem className={classes.menuItem} onClick={onUploadFile}>
              <MdUploadFile className={classes.mIcons} /> Upload Files
            </MenuItem>
          )}
          {(isFolderDownloadValid) && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                downloadFolder(files);
              }}
            >
              <MdDownload className={classes.mIcons} />
              Download Folder
            </MenuItem>
          )}
          {isValidateHandle && (
            <MenuItem className={classes.menuItem} onClick={onRename}>
              <MdEdit className={classes.mIcons} /> Rename
            </MenuItem>
          )}
          {isValidateHandle && (
            <MenuItem className={classes.menuItem} onClick={onCopyItem}>
              <MdContentCopy className={classes.mIcons} /> Copy
            </MenuItem>
          )}
          {isValidateHandle && (
            <MenuItem className={classes.menuItem} onClick={onCutItem}>
              <MdContentCut className={classes.mIcons} /> Cut
            </MenuItem>
          )}
          {isValidatePaste && (
            <MenuItem className={classes.menuItem} onClick={onPasteItem}>
              <MdContentPaste className={classes.mIcons} /> Paste
            </MenuItem>
          )}
          {selectedItem?.isFile && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                download(selectedItem);
              }}
            >
              <MdDownload className={classes.mIcons} />
              Download
            </MenuItem>
          )}
          {isValidateHandle && (
            <MenuItem
              className={[classes.menuItem, classes.menuDelete].join(' ')}
              onClick={onDeleteItem}
            >
              <MdDelete className={classes.mIcons} /> Delete
            </MenuItem>
          )}
        </Menu>
      )}
      <CreateFolderModal
        show={['folder', 'rename'].includes(show)}
        setShow={() => setShow('')}
        isLoading={loading}
        data={show == 'rename' && selectedItem}
        handleSubmit={show == 'folder' ? createFolder : updateData}
      />
      {isOpenModal === 'deleting' && (
        <AreYouSureModal
          isApiCall={false}
          setShow={() => setIsOpenModal('')}
          show={isOpenModal === 'deleting'}
          onClick={handleDeleteFolders}
          subTitle={`Do you really want to delete?`}
        />
      )}
    </>
  );
};

export default ListingFolderDetail;
