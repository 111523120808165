import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  logo: null,
  loading: false,
};

const companySlice = createSlice({
  name: 'companySlice',
  initialState,
  reducers: {
    setLogo(state, action) {
      state.logo = action.payload;
    },
    setLogoLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { setLogo, setLogoLoading } = companySlice.actions;

export default companySlice.reducer; 