import React, { useEffect } from 'react';
import classes from './CompanyProfile.module.css';
import { apiHeader, BaseURL, recordsLimit, imageUrl } from '../../config/apiUrl';
import { Delete, Get, Patch, Post } from '../../Axios/AxiosFunctions';
import { useState } from 'react';
import { Button } from '../../Component/Button/Button';
import SideBarSkeleton from '../../Component/SideBarSkeleton';
import PaginationComponent from '../../Component/PaginationComponent';
import TableSkeleton from '../../Component/TableSkeleton';
import { useSelector, useDispatch } from 'react-redux';
import NoData from '../../Component/NoData/NoData';
import AddOrUpdateCompanyProfileModal from '../../modals/AddOrUpdateCompanyProfileModal';
import { toast } from 'react-toastify';
import AreYouSureModal from '../../modals/AreYouSureModal';
import { setLogo } from '../../store/company/companySlice';
import { useNavigate } from 'react-router-dom';

const CompanyProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const superAdminEmail = process.env.REACT_APP_SUPER_ADMIN_EMAIL;
  const { access_token: accessToken, user } = useSelector(
    (state) => state?.authReducer || {},
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  // For ADD, UPDATE and DELETE
  const [isUpdating, setIsUpdating] = useState('');
  const [isOpenModal, setIsOpenModal] = useState('');

  useEffect(() => {
    if (user?.email?.toLowerCase() !== superAdminEmail) {
      navigate('/');
    }
  }, [user]);

  const getAllCategories = async () => {
    const url = BaseURL(`company?page=${page}&limit=${recordsLimit}`);
    setLoading(true);
    const response = await Get(url, accessToken);
    if (response !== undefined) {
      setData(response?.data?.data);
      setTotalPages(response?.data?.totalCount);
    }
    setLoading(false);
  };
  const createCompanyProfile = async (e) => {
    const url = BaseURL(`company/create`);
    const formData = new FormData();
    formData.append('name', e.name);
    formData.append('website', e.website); 
    formData.append('emailDomain', e.emailDomain); 
    formData.append('address', e.address);
    formData.append('allowedDomains', JSON.stringify(e.allowedDomains));
    formData.append('logo', e.photo);
    formData.append('phone', e.phone);
    formData.append('isPaidClient', e.isPaidClient);
    setIsUpdating('add');
    const response = await Post(url, formData, apiHeader(accessToken, 'multipart/form-data'));
    if (response !== undefined) {
      setData([response?.data, ...data]);
      toast.success('Category created successfully');
      setIsUpdating('');
      setIsOpenModal('');
    } else {
      setIsUpdating('');
    }
  };
  const updateCompanyProfile = async (e) => {
    const url = typeof(e.photo) == 'string' ? BaseURL(`company/admin-without-logo/${selectedItem?._id}`) : BaseURL(`company/admin/${selectedItem?._id}`);
    const formData = new FormData();
    formData.append('name', e.name);
    formData.append('website', e.website); 
    formData.append('emailDomain', e.emailDomain); 
    formData.append('address', e.address);
    formData.append('allowedDomains', JSON.stringify(e.allowedDomains));
    formData.append('logo', e.photo);
    formData.append('phone', e.phone);
    formData.append('isPaidClient', e.isPaidClient);
    setIsUpdating('edit');
    const response = typeof(e.photo) == 'string' ? await Post(url, e, apiHeader(accessToken)) : await Post(url, formData, apiHeader(accessToken, 'multipart/form-data'));
    if (response !== undefined) {
      const newData = [...data];
      newData?.splice(
        newData?.findIndex((item) => item?._id == response?.data?._id),
        1,
        response?.data,
      );
      if (response?.data?.allowedDomains?.includes(location.hostname)) {
        dispatch(setLogo(response?.data?.logoUrl));
      }
      setData(newData);
      toast.success('Company updated successfully');
      setIsUpdating('');
      setIsOpenModal('');
    } else {
      setIsUpdating('');
    }
  };

  const deleteCompanyProfile = async (e) => {
    const url = BaseURL(`company/admin/${selectedItem?._id}`);
    setIsUpdating('delete');
    const response = await Delete(url, null, apiHeader(accessToken));
    if (response !== undefined) {
      const newData = [...data];
      newData?.splice(
        newData?.findIndex((item) => item?._id == response?.data?._id),
        1,
      );
      setData(newData);
      toast.success('Category deleted successfully');
      setIsUpdating('');
      setIsOpenModal('');
    } else {
      setIsUpdating('');
    }
  };

  useEffect(() => {
    getAllCategories();
  }, [page]);

  return (
    <>
      <style>{`
      .table100-body{
      height:calc(100vh - 311px);
      }
      .table100-body td {
        padding-block: 18px;
      }
      .column1{
        padding-left: 16px;
      }
      .table100.ver1 .table100-body tr{
        margin:15px;
      }
      .table100 .table100-head tr {
        margin: 0 15px;
      }

      @media screen and (max-width:1440px){
      .table100-head, .table100-body{
        width:1200px;
      }
      .table100.ver1{
        overflow-x:scroll !important;
      }
    }
      `}</style>
      <SideBarSkeleton>
        <div className={[classes.mainContainer]}>
          <div className={classes.mainHeading}>
            <h4>Company Profile</h4>
            <Button
              label="Add New Company"
              onClick={() => setIsOpenModal('add')}
            />
          </div>
          <div className="table100 ver1 m-b-110">
            <div className="table100-head">
              <table>
                <thead>
                  <tr className="row100 head">
                    <th
                      className="cell100 column1"
                      style={{ width: '10%', textAlign: 'left' }}
                    >
                      S.No
                    </th>
                    <th
                      className="cell100 column2"
                      style={{ width: '20%', textAlign: 'left' }}
                    >
                      Company Logo
                    </th>
                    <th
                      className="cell100 column2"
                      style={{ width: '20%', textAlign: 'left' }}
                    >
                      Client Name
                    </th>
                    <th
                      className="cell100 column4"
                      style={{ width: '20%', textAlign: 'left' }}
                    >
                      Client Website
                    </th>
                    <th
                      className="cell100 column3"
                      style={{ width: '20%', textAlign: 'left' }}
                    >
                      Client Address
                    </th>
                    <th
                      className="cell100 column3"
                      style={{ width: '20%', textAlign: 'left' }}
                    >
                      Allowed Domains
                    </th>
                    <th className="cell100 column5" style={{ width: '20%' }}>
                      Actions
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            {loading ? (
              <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
            ) : (
              <div className="table100-body js-pscroll ps ps--active-y">
                <table>
                  <tbody>
                    {data?.length > 0 ? (
                      data?.map((item, index) => (
                        <tr
                          className="row100 body"
                          style={{
                            backgroundColor:
                              selectedItem?._id == item?._id && '#E5F5FF',
                          }}
                        >
                          <td
                            className="cell100 column1"
                            style={{ width: '10%', textAlign: 'left' }}
                          >
                            {index + 1 + (page - 1) * recordsLimit}
                          </td>
                          <td
                            className="cell100 column1"
                            style={{ width: '10%', textAlign: 'left' }}
                          >
                            <div style={{ position: 'relative' }}>
                              <div
                                className={[classes.teamMemberImgDiv]}
                                style={{
                                  left: '0px',
                                }}
                              >
                                <img src={imageUrl(item?.logoUrl)} alt="..." />
                              </div>
                            </div>
                          </td>
                          <td
                            className="cell100 column2"
                            style={{
                              width: '20%',
                              textAlign: 'left',
                            }}
                          >
                            {item?.name}
                          </td>

                          <td
                            className="cell100 column4"
                            style={{ width: '20%', textAlign: 'left', textTransform: 'lowercase' }}
                          >
                            {item?.website}
                          </td>
                          <td
                            className="cell100 column4"
                            style={{ width: '20%', textAlign: 'left' }}
                          >
                            {item?.address}
                          </td>
                          <td
                            className="cell100 column4"
                            style={{ width: '20%', textAlign: 'left', textTransform: 'lowercase' }}
                          >
                            {item?.allowedDomains?.join(', ')}
                          </td>
                          <td className="cell100 column5" style={{ width: '20%' }}>
                            <div className={classes.actions_btn}>
                              <Button
                                className={classes.edit_btn}
                                label={'Edit'}
                                onClick={() => {
                                  setSelectedItem(item);
                                  setIsOpenModal('edit');
                                }}
                              />
                              <Button
                                className={classes.detail_btn}
                                label={'Delete'}
                                onClick={() => {
                                  setSelectedItem(item);
                                  setIsOpenModal('delete');
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoData text={'No Listing Found'} />
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {data?.length > 0 && (
            <div className={[classes.paginationDiv]}>
              <PaginationComponent
                totalPages={Math.ceil(totalPages / recordsLimit)}
                currentPage={page}
                setCurrentPage={setPage}
              />
            </div>
          )}
        </div>
        <AddOrUpdateCompanyProfileModal
          show={isOpenModal == 'add'}
          setShow={() => setIsOpenModal('')}
          isLoading={isUpdating == 'add'}
          handleSubmit={createCompanyProfile}
        />
        <AddOrUpdateCompanyProfileModal
          show={isOpenModal == 'edit'}
          setShow={() => setIsOpenModal('')}
          isLoading={isUpdating == 'edit'}
          data={selectedItem}
          handleSubmit={updateCompanyProfile}
        />
        <AreYouSureModal
          subTitle={`Do you want to delete this company?`}
          show={isOpenModal == 'delete'}
          setShow={() => setIsOpenModal('')}
          onClick={deleteCompanyProfile}
          isApiCall={isUpdating == 'delete'}
        />
      </SideBarSkeleton>
    </>
  );
};

export default CompanyProfile;
