import {
  FaFileImage,
  FaFileAudio,
  FaFileVideo,
  FaFileArchive,
  FaFilePdf,
  FaFileCsv,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileWord,
  FaFolder,
  FaFile,
} from 'react-icons/fa';

export const IconByExtension = ({ extension }) => {
  const size = 35;

  const fileIconMap = {
    folder: <FaFolder size={size} />,
    pdf: <FaFilePdf size={size} />,
    xlsx: <FaFileExcel size={size} />,
    xls: <FaFileExcel size={size} />,
    docx: <FaFileWord size={size} />,
    doc: <FaFileWord size={size} />,
    heic: <FaFileImage size={size} />,
    heics: <FaFileImage size={size} />,
    csv: <FaFileCsv size={size} />,
    webp: <FaFileImage size={size} />,
    jpg: <FaFileImage size={size} />,
    jpeg: <FaFileImage size={size} />,
    png: <FaFileImage size={size} />,
    gif: <FaFileImage size={size} />,
    mp4: <FaFileVideo size={size} />,
    avi: <FaFileVideo size={size} />,
    mp3: <FaFileAudio size={size} />,
  };

  return fileIconMap[extension.toLowerCase()] || <FaFile size={size} />;
};
