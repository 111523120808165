import React from 'react';
import ModalSkeleton from '../ModalSkeleton';
import { Row } from 'react-bootstrap';
import { Button } from '../../Component/Button/Button';
import { useFormik } from 'formik';
import classes from './ContractInputFieldModal.module.css';
import { Input } from '../../Component/Input/Input';
import { TextArea } from '../../Component/TextArea';
import { DropDown } from '../../Component/DropDown/DropDown';

const ContractInputFieldModal = ({
  isOpen,
  setIsOpen,
  editData,
  listings = [],
  entities = [],
  sellers = [],
  buyers = [],
  cooperatingBroker = [],
  thirdPartyContacts = [],
  brokers = [],
  handleOnSubmit,
}) => {
  const { values, handleSubmit, setFieldValue, isSubmitting } = useFormik({
    initialValues: {
      // Listing

      listing: editData?.listing,
      entity: editData?.entity,
      business_name: editData?.business_name || '',
      business_address: editData?.business_address || '',

      buyer: editData?.buyer,
      seller: editData?.seller,

      recipients: editData?.recipients || [],
      // broker: editData?.// broker||'',

      broker: editData?.broker || '',
      broker_firm_name: editData?.broker_firm_name || '',
      broker_provided_info: editData?.broker_provided_info || '',

      cooperating_brokerage_name: editData?.cooperating_brokerage_name || '',
      cooperating_brokerage_address:
        editData?.cooperating_brokerage_address || '',
      commission_percentage: editData?.commission_percentage || '',
      commission_payment_terms: editData?.commission_payment_terms || '',

      //Cooperating Broker
      cooperating_broker_name: editData?.cooperating_broker_name || '',
      cooperating_broker_title: editData?.cooperating_broker_title || '',
      cooperating_broker_phone: editData?.cooperating_broker_phone || '',
      cooperating_broker_email: editData?.cooperating_broker_email || '',

      // Agreement
      agreement_title: editData?.agreement_title || '',
      agreement_date: editData?.agreement_date || '',
      closing_date: editData?.closing_date || '',

      //
      termination_reason: editData?.termination_reason || '',
      clause_reference: editData?.clause_reference || '',
      due_diligence_deadline: editData?.due_diligence_deadline || '',
      document_list: editData?.document_list || '',

      earnest_money_amount: editData?.earnest_money_amount || '',
      escrow_agent_name: editData?.escrow_agent_name || '',
      escrow_company_name: editData?.escrow_company_name || '',

      buyer_execution_date_1: editData?.buyer_execution_date_1 || '',
      buyer_execution_date_2: editData?.buyer_execution_date_2 || '',
      seller_execution_date: editData?.seller_execution_date || '',
      date_executed: editData?.date_executed || '',
    },
    enableReinitialize: true,
    onSubmit: async (value, { setSubmitting }) => {
      setSubmitting(true);
      handleOnSubmit(value);
      setIsOpen(false);
      setSubmitting(false);
    },
  });

  return (
    <>
      {/* <style>{`
      .MuiFormControl-root{
        width: 100%;
      }
      .MuiFormLabel-root {
        color: var(--placeholder-color) !important;
      }
      .MuiOutlinedInput-notchedOutline {
        box-shadow: 0px 0 5px 2px #0000000d;
        border-width: 0;
        border: none;
        border-radius: 10px;
        box-shadow: 0px 0 5px 2px #0000000d;
      }
      `}</style> */}

      <ModalSkeleton
        show={isOpen}
        setShow={setIsOpen}
        borderRadius="20px"
        width="1000px"
        borderLine={false}
        header={'Select Contract Field'}
        showCloseIcon={true}
      >
        <form
          onSubmit={handleSubmit}
          className={classes.addEmailTemplateModal_main}
        >
          <Row className={classes.input_row}>
            <DropDown
              setter={(item) => {
                setFieldValue('listing', item);
                setFieldValue('business_name', item?.companyName);
                setFieldValue('commission_percentage', item?.percentage);

                if(item?.businessAddress?.length){
                  setFieldValue('business_address', item?.businessAddress?.[0])
                }

                const entity = entities?.find((en) => en?._id === item?.entity);

                if (entity) {
                  setFieldValue('entity', entity);
                }

                console.log('item?.onwer', item);

                item?.owner?.forEach((s) => {
                  const seller = sellers?.find((en) => en?._id === s?._id);

                  if (seller) {
                    setFieldValue('seller', seller);

                    const signOrderData = values?.recipients?.filter(
                      (user) => user?.role !== 'Seller',
                    );
    
                    signOrderData.push({
                      send_email: true,
                      send_email_delay: 0,
                      id: seller?._id,
                      name: seller?.name,
                      email: seller?.email,
                      subject: 'Contract Agreement for Signature by SignWell',
                      message: 'Hello, Please review agreement and do signature',
                      role: 'Seller',
                    });
    
                    setFieldValue('recipients', signOrderData);
                  }
                });

                item?.buyer?.forEach((id) => {
                  const buyer = buyers?.find((bu) => bu?._id === id);

                  if (buyer) {
                    setFieldValue('buyer', buyer);

                    const signOrderData = values?.recipients?.filter(
                      (user) => user?.role !== 'Buyer',
                    );
    
                    signOrderData.push({
                      send_email: true,
                      send_email_delay: 0,
                      id: buyer?._id,
                      name: buyer?.name,
                      email: buyer?.email,
                      subject: 'Contract Agreement for Signature by SignWell',
                      message: 'Hello, Please review agreement and do signature',
                      role: 'Buyer',
                    });
    
                    setFieldValue('recipients', signOrderData);
                  }
                });

                if (entity) {
                  setFieldValue('entity', entity);
                }
              }}
              value={values?.listing}
              placeholder={'Choose Listing'}
              options={listings}
              optionLabel={'companyName'}
              optionValue={'_id'}
              label={'Listing'}
            />

            <DropDown
              setter={(item) => {
                setFieldValue('entity', item);
              }}
              value={values?.entity}
              placeholder={'Choose Entity'}
              options={entities}
              optionLabel={'name'}
              optionValue={'_id'}
              label={'Entity'}
            />

            <Input
              value={values?.business_name}
              setter={(e) => {
                setFieldValue('business_name', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Business Name'}
              label={'Business Name'}
            />

            <Input
              value={values?.business_address}
              setter={(e) => {
                setFieldValue('business_address', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Business Address'}
              label={'Business Address'}
            />

            <DropDown
              setter={(item) => {
                setFieldValue('seller', item);

                const signOrderData = values?.recipients?.filter(
                  (user) => user?.role !== 'Seller',
                );

                signOrderData.push({
                  send_email: true,
                  send_email_delay: 0,
                  id: item?._id,
                  name: item?.name,
                  email: item?.email,
                  subject: 'Contract Agreement for Signature by SignWell',
                  message: 'Hello, Please review agreement and do signature',
                  role: 'Seller',
                });

                setFieldValue('recipients', signOrderData);
              }}
              value={values?.seller}
              placeholder={'Seller Name'}
              options={sellers}
              optionLabel={'name'}
              optionValue={'_id'}
              label={'Seller Name'}
            />

            <DropDown
              setter={(item) => {
                setFieldValue('buyer', item);

                const signOrderData = values?.recipients?.filter(
                  (user) => user?.role !== 'Buyer',
                );

                signOrderData.push({
                  send_email: true,
                  send_email_delay: 0,
                  id: item?._id,
                  name: item?.name,
                  email: item?.email,
                  subject: 'Contract Agreement for Signature by SignWell',
                  message: 'Hello, Please review agreement and do signature',
                  role: 'Buyer',
                });

                setFieldValue('recipients', signOrderData);
              }}
              value={values?.buyer}
              placeholder={'Buyer Name'}
              options={buyers}
              optionLabel={'name'}
              optionValue={'_id'}
              label={'Buyer Name'}
            />

            <DropDown
              setter={(item) => {
                setFieldValue('broker', item);
                setFieldValue('broker_firm_name', item?.company?.name || '');

                const signOrderData = values?.recipients?.filter(
                  (user) => user?.role !== 'Broker',
                );

                signOrderData.push({
                  send_email: true,
                  send_email_delay: 0,
                  id: item?._id,
                  name: item?.name,
                  email: item?.email,
                  subject: 'Contract Agreement for Signature by SignWell',
                  message: 'Hello, Please review agreement and do signature',
                  role: 'Broker',
                });

                setFieldValue('recipients', signOrderData);
              }}
              value={values?.broker}
              placeholder={'Broker Name'}
              options={brokers}
              optionLabel={'name'}
              optionValue={'_id'}
              label={'Broker Name'}
            />

            <Input
              value={values?.broker_firm_name}
              setter={(e) => {
                setFieldValue('broker_firm_name', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Broker Firm Name'}
              label={'Broker Firm Name'}
            />

            <div className={classes.col_span_2}>
              <TextArea
                value={values?.broker_provided_info}
                setter={(e) => {
                  setFieldValue('broker_provided_info', e);
                }}
                customStyle={{
                  borderRadius: '10px',
                  border: 'none',
                }}
                inputStyle={{ borderColor: 'none' }}
                placeholder={'Broker Provided Info'}
                label={'Broker Provided Info'}
              />
            </div>

            <Input
              value={values?.cooperating_brokerage_name}
              setter={(e) => {
                setFieldValue('cooperating_brokerage_name', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Cooperating Brokerage Name'}
              label={'Cooperating Brokerage Name'}
            />

            <Input
              value={values?.cooperating_brokerage_address}
              setter={(e) => {
                setFieldValue('cooperating_brokerage_address', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Cooperating Brokerage Address'}
              label={'Cooperating Brokerage Address'}
            />

            <Input
              value={values?.commission_percentage}
              setter={(e) => {
                setFieldValue('commission_percentage', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Commission Percentage'}
              label={'Commission Percentage'}
            />

            <div className={classes.col_span_2}>
              <TextArea
                value={values?.commission_payment_terms}
                setter={(e) => {
                  setFieldValue('commission_payment_terms', e);
                }}
                customStyle={{
                  borderRadius: '10px',
                  border: 'none',
                }}
                inputStyle={{ borderColor: 'none' }}
                placeholder={'Commission Payment Terms'}
                label={'Commission Payment Terms'}
              />
            </div>

            <DropDown
              setter={(item) => {
                setFieldValue('cooperating_broker_name', item);
                setFieldValue('cooperating_broker_email', item?.email);
                setFieldValue('cooperating_broker_phone', item?.contact);
              }}
              value={values?.cooperating_broker_name}
              placeholder={'Cooperating Broker Name'}
              options={cooperatingBroker}
              optionLabel={'name'}
              optionValue={'_id'}
              label={'Cooperating Broker Name'}
            />

            <Input
              value={values?.cooperating_broker_title}
              setter={(e) => {
                setFieldValue('cooperating_broker_title', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Cooperating Broker Title'}
              label={'Cooperating Broker Title'}
            />

            <Input
              value={values?.cooperating_broker_phone}
              setter={(e) => {
                setFieldValue('cooperating_broker_phone', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Cooperating Broker Phone'}
              label={'Cooperating Broker Phone'}
            />

            <Input
              value={values?.cooperating_broker_email}
              setter={(e) => {
                setFieldValue('cooperating_broker_email', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Cooperating Broker Email'}
              label={'Cooperating Broker Email'}
            />

            <Input
              value={values?.agreement_title}
              setter={(e) => {
                setFieldValue('agreement_title', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Agreement Title'}
              label={'Agreement Title'}
            />

            <Input
              value={values?.agreement_date}
              setter={(e) => {
                setFieldValue('agreement_date', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Agreement Date'}
              label={'Agreement Date'}
              type={'date'}
            />

            <Input
              value={values?.closing_date}
              setter={(e) => {
                setFieldValue('closing_date', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Closing Date'}
              label={'Closing Date'}
              type={'date'}
            />

            <div className={classes.col_span_2}>
              <TextArea
                value={values?.termination_reason}
                setter={(e) => {
                  setFieldValue('termination_reason', e);
                }}
                customStyle={{
                  borderRadius: '10px',
                  border: 'none',
                }}
                inputStyle={{ borderColor: 'none' }}
                placeholder={'Termination Reason'}
                label={'Termination Reason'}
              />
            </div>

            <div className={classes.col_span_2}>
              <Input
                value={values?.clause_reference}
                setter={(e) => {
                  setFieldValue('clause_reference', e);
                }}
                customStyle={{
                  borderRadius: '10px',
                  border: 'none',
                  padding: 0,
                }}
                inputStyle={{ borderColor: 'none' }}
                placeholder={'Clause Reference'}
                label={'Clause Reference'}
              />
            </div>

            <Input
              value={values?.due_diligence_deadline}
              setter={(e) => {
                setFieldValue('due_diligence_deadline', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Due Diligence Deadline'}
              label={'Due Diligence Deadline'}
            />

            <Input
              value={values?.document_list}
              setter={(e) => {
                setFieldValue('document_list', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Document List'}
              label={'Document List'}
            />

            {/* <Input
              value={values?.agreement_title}
              setter={(e) => {
                setFieldValue('agreement_title', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Agreement Title'}
              label={'Agreement Title'}
            />

            <Input
              value={values?.agreement_date}
              setter={(e) => {
                setFieldValue('agreement_date', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Agreement Date'}
              label={'Agreement Date'}
              type={'date'}
            /> */}

            <Input
              value={values?.earnest_money_amount}
              setter={(e) => {
                setFieldValue('earnest_money_amount', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Earnest Money Amount'}
              label={'Earnest Money Amount'}
            />

            <DropDown
              setter={(item) => {
                setFieldValue('escrow_agent_name', item);
                setFieldValue('escrow_company_name', item?.company?.name);
              }}
              value={values?.escrow_agent_name}
              options={thirdPartyContacts}
              optionLabel={'name'}
              optionValue={'_id'}
              placeholder={'Escrow Agent Name'}
              label={'Escrow Agent Name'}
            />

            <Input
              value={values?.escrow_company_name}
              setter={(e) => {
                setFieldValue('escrow_company_name', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Escrow Company Name'}
              label={'Escrow Company Name'}
            />

            <div />

            <Input
              value={values?.buyer_execution_date_1}
              setter={(e) => {
                setFieldValue('buyer_execution_date_1', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Buyer Execution Date 1'}
              label={'Buyer Execution Date 1'}
              type={'date'}
            />

            <Input
              value={values?.buyer_execution_date_2}
              setter={(e) => {
                setFieldValue('buyer_execution_date_2', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Buyer Execution Date 2'}
              label={'Buyer Execution Date 2'}
              type={'date'}
            />

            <Input
              value={values?.seller_execution_date}
              setter={(e) => {
                setFieldValue('seller_execution_date', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Seller Execution Date'}
              label={'Seller Execution Date'}
              type={'date'}
            />

            <Input
              value={values?.date_executed}
              setter={(e) => {
                setFieldValue('date_executed', e);
              }}
              customStyle={{
                borderRadius: '10px',
                border: 'none',
                padding: 0,
              }}
              inputStyle={{ borderColor: 'none' }}
              placeholder={'Date Executed'}
              label={'Date Executed'}
              type={'date'}
            />
          </Row>

          <div className={classes.btn_main}>
            <Button
              type="submit"
              className={classes.btn}
              label={
                isSubmitting && values?.status === 'draft'
                  ? 'Submitting...'
                  : 'Save'
              }
              disabled={isSubmitting && values?.status === 'draft'}
              onClick={() => {
                setFieldValue('status', 'draft');
              }}
            />
          </div>
        </form>
      </ModalSkeleton>
    </>
  );
};

export default ContractInputFieldModal;
