import React, { useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { Button } from '../../Component/Button/Button';
import classes from './AddContractTemplate.module.css';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { MSG_REQUIRED_FIELD } from '../../utils/contants';
import {
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
  Typography,
} from '@mui/material';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from 'react-redux';
import { apiHeader, BaseURL } from '../../config/apiUrl';
import { Patch, Post } from '../../Axios/AxiosFunctions';
import { toast } from 'react-toastify';
import SideBarSkeleton from '../../Component/SideBarSkeleton';
import { MdContentCopy } from 'react-icons/md';
import { FiPlusCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const VARIABLES = [
  {
    id: 0,
    label: 'Company Logo',
    value: '*|company_logo|*',
  },
  {
    id: 11,
    label: 'Entity Name',
    value: '*|entity_name|*',
  },
  {
    id: 111,
    label: 'Entity Street',
    value: '*|entity_street|*',
  },
  {
    id: 122,
    label: 'Entity City',
    value: '*|entity_city|*',
  },
  {
    id: 122,
    label: 'Entity State',
    value: '*|entity_state|*',
  },
  {
    id: 122,
    label: 'Entity Zip',
    value: '*|entity_zip|*',
  },
  {
    id: 122,
    label: 'Entity Country',
    value: '*|entity_country|*',
  },
  {
    id: 122,
    label: 'Entity Phone',
    value: '*|entity_phone|*',
  },
  {
    id: 1,
    label: 'Brokerage firm name',
    value: '*|listing_brokerage_name|*',
  },
  {
    id: 2,
    label: 'Brokerage firm address',
    value: '*|listing_brokerage_address|*',
  },
  {
    id: 3,
    label: 'Cooperating Brokerage Name',
    value: '*|cooperating_brokerage_name|*',
  },
  {
    id: 4,
    label: 'Cooperating Brokerage Address',
    value: '*|cooperating_brokerage_address|*',
  },
  {
    id: 5,
    label: 'Commission Percentage',
    value: '*|commission_percentage|*',
  },
  {
    id: 6,
    label: 'Commission Payment Terms',
    value: '*|commission_payment_terms|*',
  },
  {
    id: 7,
    label: 'Cooperating Broker Name',
    value: '*|cooperating_broker_name|*',
  },
  {
    id: 8,
    label: 'Cooperating Broker Title',
    value: '*|cooperating_broker_title|*',
  },
  {
    id: 9,
    label: 'Cooperating Broker Phone',
    value: '*|cooperating_broker_phone|*',
  },
  {
    id: 10,
    label: 'Cooperating Broker Email',
    value: '*|cooperating_broker_email|*',
  },
  {
    id: 11,
    label: 'Listing Broker Name',
    value: '*|listing_broker_name|*',
  },
  {
    id: 12,
    label: 'Listing Broker Title',
    value: '*|listing_broker_title|*',
  },
  {
    id: 13,
    label: 'Listing Broker Phone',
    value: '*|listing_broker_phone|*',
  },
  {
    id: 14,
    label: 'Listing Broker Email',
    value: '*|listing_broker_email|*',
  },
  {
    id: 15,
    label: 'Agreement Date',
    value: '*|Agreement_Date|*',
  },
  {
    id: 16,
    label: 'Closing Date',
    value: '*|closing_date|*',
  },
  {
    id: 17,
    label: 'Agreement Title',
    value: '*|Agreement_Title|*',
  },

  {
    id: 19,
    label: 'Buyer Name',
    value: '*|Buyer_Name|*',
  },
  {
    id: 20,
    label: 'Seller Name',
    value: '*|Seller_Name|*',
  },
  {
    id: 21,
    label: 'Business Name',
    value: '*|Business_Name|*',
  },
  {
    id: 22,
    label: 'Business Address',
    value: '*|Business_Address|*',
  },
  {
    id: 23,
    label: 'Termination Reason',
    value: '*|Termination_Reason|*',
  },
  {
    id: 24,
    label: 'Clause Reference',
    value: '*|Clause_Reference|*',
  },
  {
    id: 25,
    label: 'Due Diligence Deadline',
    value: '*|Due_Diligence_Deadline|*',
  },
  {
    id: 26,
    label: 'Document List',
    value: '*|Document_List|*',
  },
  {
    id: 27,
    label: 'Buyer Signature Name',
    value: '*|Buyer_Signature_Name|*',
  },
  {
    id: 28,
    label: 'Buyer Signature Date',
    value: '*|Buyer_Signature_Date|*',
  },
  {
    id: 29,
    label: 'Seller Signature Name',
    value: '*|Seller_Signature_Name|*',
  },
  {
    id: 30,
    label: 'Seller Signature Date',
    value: '*|Seller_Signature_Date|*',
  },
  {
    id: 31,
    label: 'Earnest Money Amount',
    value: '*|earnest_money_amount|*',
  },
  {
    id: 32,
    label: 'Escrow Agent Name',
    value: '*|escrow_agent_name|*',
  },
  {
    id: 33,
    label: 'Escrow Company Name',
    value: '*|escrow_company_name|*',
  },
  {
    id: 34,
    label: 'Buyer Execution Date 1',
    value: '*|buyer_execution_date_1|*',
  },
  {
    id: 35,
    label: 'Buyer Execution Date 2',
    value: '*|buyer_execution_date_2|*',
  },
  {
    id: 36,
    label: 'Seller Execution Date',
    value: '*|seller_execution_date|*',
  },
  {
    id: 37,
    label: 'Date Executed',
    value: '*|date_executed|*',
  },
  // {
  //   id: 38,
  //   label: 'Cooperating Broker Telephone',
  //   value: '*|cooperating_broker_telephone|*',
  // },
  // {
  //   id: 39,
  //   label: 'Cooperating Broker Email',
  //   value: '*|cooperating_broker_email|*',
  // },
  {
    id: 40,
    label: 'Listing Broker Telephone',
    value: '*|listing_broker_telephone|*',
  },
  {
    id: 41,
    label: 'Listing Broker Email',
    value: '*|listing_broker_email|*',
  },
  {
    id: 42,
    label: 'Listing Broker Signature Date',
    value: '*|listing_broker_signature_date|*',
  },
  {
    id: 43,
    label: 'Document Date',
    value: '*|document_date|*',
  },
  {
    id: 44,
    label: 'Signature 1',
    value: '*|signature_1|*',
  },
  {
    id: 45,
    label: 'Signature 2',
    value: '*|signature_2|*',
  },
  {
    id: 46,
    label: 'Broker Provided Info',
    value: '*|broker_provided_info|*',
  },
  {
    id: 47,
    label: 'Broker Firm Name',
    value: '*|broker_firm_name|*',
  },
  {
    id: 48,
    label: 'Broker Name',
    value: '*|broker_name|*',
  },
];

const TRIGGER = [
  { value: 'CONTRACTS', label: 'Contracts' },
  { value: 'AMENDMENTS', label: 'Amendments' },
];

const validationSchema = yup.object().shape({
  type: yup.string().required(MSG_REQUIRED_FIELD),
  name: yup.string().required(MSG_REQUIRED_FIELD),
  content: yup.string().required(MSG_REQUIRED_FIELD),
});

const AddContractTemplate = ({ editData }) => {
  const editorRef = useRef();

  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const baseApiUrl = BaseURL(`contract-template`);

  const navigate = useNavigate();

  const [variables, setVariables] = useState(VARIABLES);
  const [editorInstance, setEditorInstance] = useState(null);

  const {
    errors,
    values,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    resetForm,
    handleBlur,
  } = useFormik({
    initialValues: {
      type: editData?.type || '',
      name: editData?.name || '',
      content: editData?.content || '',
      isEnabled: editData?.isEnabled,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({ type, name, content }) => {
      const body = {
        type,
        name,
        content,
        isEnabled: true,
      };

      setSubmitting(true);

      if (editData?._id) {
        const response = await Patch(
          `${baseApiUrl}/${editData?._id}`,
          body,
          apiHeader(accessToken),
        );

        if (response !== undefined) {
          toast.success(`Template Edited Successfully.`);
          setSubmitting(false);
          navigate('/contract-templates');
        }
      } else {
        const response = await Post(baseApiUrl, body, apiHeader(accessToken));

        if (response !== undefined) {
          toast.success(`Template Created Successfully.`);
          setSubmitting(false);
          navigate('/contract-templates');
          resetForm();
        }
      }
    },
  });

  const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);

      toast.success('Copied successfully');
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  const addText = (text) => {
    if (editorInstance) {
      editorInstance?.insertHTML(text);
      toast.success('Field added successfully');
    }
  };

  return (
    <>
      <SideBarSkeleton>
        <div className={classes.container_main}>
          <div className={classes.main_heading}>
            <h4>{editData?._id ? 'Edit' : 'Add'} Contract Template</h4>
          </div>

          <form
            onSubmit={handleSubmit}
            className={classes.addEmailTemplateModal_main}
          >
            <Row className={classes.input_row}>
              <FormControl
                fullWidth
                error={touched.type && Boolean(errors.type)}
              >
                <InputLabel id="type-label">Template Type *</InputLabel>
                <Select
                  labelId="type-label"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Template Type *"
                >
                  {TRIGGER.map((trigger) => (
                    <MenuItem key={trigger.value} value={trigger.value}>
                      {trigger.label}
                    </MenuItem>
                  ))}
                </Select>

                {touched.type && errors.type && (
                  <FormHelperText>{errors.type}</FormHelperText>
                )}
              </FormControl>

              <TextField
                label="Name *"
                name="name"
                onChange={handleChange}
                value={values.name}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />

              <div className={classes.var_section}>
                <TextField
                  label="Search *"
                  onChange={(e) => {
                    const value = e?.target?.value?.toLowerCase()?.trim();
                    if (value) {
                      setVariables(
                        VARIABLES?.filter(
                          (item) =>
                            item?.label?.toLowerCase()?.includes(value) ||
                            item?.value?.toLowerCase()?.includes(value),
                        ),
                      );
                    } else {
                      setVariables(VARIABLES);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <div className={classes?.variables}>
                  {variables &&
                    variables?.length > 0 &&
                    variables.map((variable, index) => (
                      <div className={classes.var_item} key={index}>
                        {variable.label} ({variable.value})
                        <div className={classes.action_button}>
                          <button
                            type="button"
                            onClick={() => addText(variable.value)}
                            style={{ opacity: 0.8 }}
                          >
                            <FiPlusCircle size={20} />
                          </button>

                          <button
                            type="button"
                            onClick={() => copyText(variable.value)}
                          >
                            <MdContentCopy size={18} />
                          </button>
                        </div>
                      </div>
                    ))}

                  {variables && !variables?.length && (
                    <Typography textAlign={'center'}>No Data Found</Typography>
                  )}
                </div>
              </div>

              <div className={classes.textEditor}>
                <SunEditor
                  setOptions={{
                    buttonList: [
                      ['undo', 'redo'],
                      ['font', 'fontSize', 'formatBlock'],
                      [
                        'bold',
                        'underline',
                        'italic',
                        'strike',
                        'subscript',
                        'superscript',
                      ],
                      ['align', 'horizontalRule', 'list', 'table'],
                      ['fontColor', 'hiliteColor', 'textStyle', 'lineHeight'],
                      ['outdent', 'indent'],
                      ['removeFormat'],
                      ['link', 'image'],
                      ['fullScreen'],
                      // ['preview', 'print'],
                      // ['fullScreen', 'showBlocks', 'codeView'],
                    ],
                  }}
                  onChange={(value) => {
                    setFieldValue('content', value);
                  }}
                  defaultValue={values?.content}
                  height="460px"
                  setContents={values?.content}
                  getSunEditorInstance={(instance) =>
                    setEditorInstance(instance)
                  }
                />
              </div>
            </Row>

            <div className={classes.btn_main}>
              <Button
                type="submit"
                className={classes.btn}
                label={
                  isSubmitting
                    ? 'Submitting...'
                    : editData?._id
                      ? 'Edit Template'
                      : 'Add Template'
                }
                disabled={isSubmitting}
              />
            </div>
          </form>
        </div>
      </SideBarSkeleton>
    </>
  );
};

export default AddContractTemplate;
